import { database } from "../library/init-firebase";
import { get, child, ref, update } from "firebase/database";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [simpleUsers, setSimpleUsers] = useState([]);
  const [businessUsers, setBusinessUsers] = useState([]);
  const [user, setUser] = useState([]);
  const params = useParams();

  const getAllUsers = async () => {
    await get(child(ref(database), "Users"))
      .then((users) => {
        if (users.exists()) {
          let user = [];
          users.forEach((childUser) => {
            user.push(childUser.val());
          });
          let simpleUser = user.filter(
            (type) => type.userType === "simpleuser"
          );
          let businessUser = user.filter(
            (type) => type.userType === "businessuser"
          );
          setSimpleUsers(simpleUser);
          setBusinessUsers(businessUser);
          setUsers(user);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getAllUsers(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    await get(child(ref(database), "Users/" + params.uid))
      .then((user) => {
        if (user.exists()) {
          setUser(user.val());
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStatus = async (id, isActive) => {
    await update(child(ref(database), "Users/" + id), {
      isActive: isActive === "Pending" ? "Active" : "Pending",
    })
      .then(async () => {
        await get(child(ref(database), "Users"))
          .then((users) => {
            if (users.exists()) {
              let user = [];
              users.forEach((childUser) => {
                user.push(childUser.val());
              });
              let businessUser = user.filter(
                (type) => type.userType === "businessuser"
              );
              setBusinessUsers(businessUser);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return {
    users,
    simpleUsers,
    businessUsers,
    getAllUsers,
    getUser,
    user,
    changeStatus,
  };
};

export default useUsers;
