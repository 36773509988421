import "./simpleUser.scss";
import { DataGrid } from "@mui/x-data-grid";
import useUsers from "../../hooks/userUsers";
import { useNavigate } from "react-router-dom";
import userPic from "../../images/user.png"
import { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const SimpleUser = () => {
  const { simpleUsers } = useUsers();

  const navigate = useNavigate();

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => navigate(`/users/${params.row.userType}/${params.row.uid}`)}
            >
              View
            </div>
          </div>
        );
      },
    },
  ];
  const userColumns = [
    {
      field: "user",
      headerName: "User Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={params.row.profilePicture ? params.row.profilePicture : userPic}
              alt="avatar"
            />
            {params.row.firstName} {params.row.lastName}
          </div>
        );
      },
    },
    {
      field: "phoneNo",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "location",
      headerName: "Location",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.city}, {params.row.country}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.isActive}`}>
            {params.row.isActive}
          </div>
        );
      },
    },
  ];
  const [searchField, setSearchField] = useState("");

  const filteredPersons = simpleUsers.filter(
    person => {
      return (
        person
          .firstName
          .toLowerCase()
          .includes(searchField.toLowerCase()) ||
        person
          .lastName
          .toLowerCase()
          .includes(searchField.toLowerCase())
      );
    }
  );

  const handlesearch = e => {
    setSearchField(e.target.value);
  };

  return (
    <div className="datatable">
      <div className="backBtn">
        <div className="backBtn-inner">

          <div
            className="backButton"
            onClick={() => navigate('/home')}
          >
            Back
          </div>
        </div>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '24ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField id="standard-basic" label="Search" variant="standard" onChange={handlesearch} value={searchField} />
        </Box>
      </div>
      <DataGrid
        sx={{ maxHeight: "70vh" }}
        className="datagrid"
        getRowId={(row) => row.uid}
        rows={filteredPersons}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        disableColumnMenu
      />
    </div>
  );
};

export default SimpleUser;
