import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../library/init-firebase";

const Sidebar = () => {

  const logout = async () => {
    try {
      await signOut(auth).then(() => {
        sessionStorage.clear();
        window.location.replace("/")
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <>
      <div className="sidebar">
        <div className="top">
          <Link to="/home" style={{ textDecoration: "none" }}>
            <span className="logo">Admin</span>
          </Link>
        </div>
        <hr />
        <div className="center">
          <ul>
            <p className="title">MAIN</p>
            <li>
              <Link to="/home" style={{ textDecoration: "none" }}>
                <DashboardIcon className="icon" />
                <span>Dashboard</span>
              </Link>
            </li>
            <p className="title">Users</p>
            <Link to="/users/simpleuser" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>Simple Users</span>
              </li>
            </Link>
            <Link to="/users/businessuser" style={{ textDecoration: "none" }}>
              <li>
                <PersonOutlineIcon className="icon" />
                <span>Business Users</span>
              </li>
            </Link>
            <p className="title">Settings</p>
            <li onClick={logout}>
              <ExitToAppIcon className="icon" />
              <span>Logout</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
