import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  let user = JSON.parse(sessionStorage.getItem("user"));

  return user != null ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
