import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Single from "./pages/single/Single";
import { Routes, Route, Navigate } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Navbar";
import SimpleUser from "./components/simpleUser/SimpleUser";
import BusinessUser from "./components/businessUser/BusinessUser";
import PrivateRoute from "./hooks/PrivateRoute";

function App() {
  let user = JSON.parse(sessionStorage.getItem("user"));
  const { darkMode } = useContext(DarkModeContext);
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <Routes>
        <Route
          path="/"
          element={
            user != null ? <Navigate to="/home" /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/login"
          element={user == null ? <Login /> : <Navigate to="/home" />}
        />
        <Route
          path="/forgetpassword"
          element={user != null ? <Navigate to="/home" /> : <ForgetPassword />}
        />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/simpleuser"
          element={
            <PrivateRoute>
              <div className="list">
                <Sidebar />
                <div className="listContainer">
                  <Navbar />
                  <SimpleUser />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/users/businessuser"
          element={
            <PrivateRoute>
              <div className="list">
                <Sidebar />
                <div className="listContainer">
                  <Navbar />
                  <BusinessUser />
                </div>
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/users/:userType/:uid"
          element={
            <PrivateRoute>
              <Single />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
