import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "../../components/simpleUser/simpleUser.scss";
import useUsers from "../../hooks/userUsers";
import { useNavigate, useParams } from "react-router-dom";
import userPic from "../../images/user.png"

const Single = () => {
  const navigate = useNavigate();
  const params = useParams()
  const { user } = useUsers();
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="backBtn">
            <div
              className="backButton"
              onClick={() => navigate(`/users/${params.userType}`)}
            >
              Back
            </div>
          </div>
          <div className="left">
            <h1 className="title">Personal Information</h1>
            <div className="item">
              <img
                src={user.profilePicture ? user.profilePicture : userPic}
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle">
                  {user.firstName}
                  {user.lastName}
                </h1>
                <div className="detailItem">
                  <span className="itemKey">User Type:</span>
                  <span className="itemValue">{user.userType}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{user.phoneNo}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">City:</span>
                  <span className="itemValue">{user.city}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{user.country}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Status:</span>
                  <span className="itemValue">{user.isActive}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Distance Range:</span>
                  <span className="itemValue">{user.distanceRange}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Longitude & Latitude:</span>
                  <span className="itemValue">
                    {user.longitude}, {user.latitude}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {user.userType === "businessuser" ? (
            <div className="left">
              <h1 className="title">Business Information</h1>
              <div className="item">
                <img src={user.businessPicture ? user.businessPicture : userPic} alt="" className="itemImg" />
                <div className="details">
                  <h1 className="itemTitle">{user.businessName}</h1>
                  <div className="detailItem">
                    <span className="itemKey">Business Type:</span>
                    <span className="itemValue">{user.businessType}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Business Phone:</span>
                    <span className="itemValue">{user.businessNumber}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Business Address:</span>
                    <span className="itemValue">{user.businessAddress}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">City:</span>
                    <span className="itemValue">{user.city}</span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    <span className="itemValue">{user.country}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Single;
