import "./widget.scss";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import useUsers from "../../hooks/userUsers";
const Widget = ({ type }) => {
  let data;
  const { users, simpleUsers, businessUsers } = useUsers();
  switch (type) {
    case "total":
      data = {
        title: "USERS",
        isSize: users?.length,
        link: "See all users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "local":
      data = {
        title: "Simple Users",
        isSize: simpleUsers?.length,
        link: "View all local users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "business":
      data = {
        title: "Business Users",
        isSize: businessUsers?.length,
        link: "View all business users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data?.title}</span>
        <span className="counter">{data?.isSize}</span>
      </div>
      <div className="right">{data?.icon}</div>
    </div>
  );
};

export default Widget;
