import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyCsmsCpf7Y3o0M3DlpmWePnl3oMLRzPvec",
  authDomain: "chatloc8.firebaseapp.com",
  databaseURL: "https://chatloc8-default-rtdb.firebaseio.com",
  projectId: "chatloc8",
  storageBucket: "chatloc8.appspot.com",
  messagingSenderId: "951430699257",
  appId: "1:951430699257:web:e2bb2147b88ede8a432a28",
};

const app = initializeApp(firebaseConfig);

export const database = getDatabase(app);
export const auth = getAuth(app);
